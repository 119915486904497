import store from '@/state/store'

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/dashboards/main/index')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },

  

  {
    path: '/produce/produce_list',
    name: 'produce-list',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/produce/produce_list')
  },


  {
    path: '/produce/task_list',
    name: 'produce-task-list',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/produce/task_list')
  },


  {
    path: '/produce/produce_model_detail',
    name: 'produce-model-detail',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/produce/produce_model_detail')
  },

  


  {
    path: '/produce/new_task',
    name: 'task-new',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/produce/new_task')
  },

  {
    path: '/produce/show_task',
    name: 'task-detail',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/produce/task_detail')
  },


  {
    path: '/stock/list',
    name: 'stock-list',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/stock/list')
  },

  {
    path: '/stock/in',
    name: 'stock-in',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/stock/in_stock')
  },

  {
    path: '/stock/out',
    name: 'stock-out',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/stock/out_stock')
  },

  {
    path: '/stock/transfer',
    name: 'stock-transfer',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/stock/transfer_stock')
  },


  {
    path: '/stock/records',
    name: 'stock-records',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/stock/stock_record')
  },

  {
    path: '/stock/records_approve',
    name: 'stock-records-approve',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/stock/stock_record_approve')
  },
  

  {
    path: '/assemble/list',
    name: 'assemble-list',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/stock/assemble_list')
  },


  {
    path: '/system/basic',
    name: 'system-basic',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/system/basic')
  },

  {
    path: '/system/produce',
    name: 'system-produce',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/system/produce')
  },

]   
